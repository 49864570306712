import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Testimonials from "../components/testimonials"
import Banner from "../components/banner"
import Pricing from "../components/pricing"
import StickyCTA from "../components/stickyCTA"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark

  const testimonials = frontmatter.testimonials.map(testimonial => ({
    title: testimonial.title,
    image: <Img fluid={testimonial.image.childImageSharp.fluid} />,
  }))

  const heroUsps = [
    "Huis naar huis verhuizing",
    "Zakelijk verhuizen",
    "Internationaal verhuizen",
    "Spoed verhuizing",
    "Verhuislift huren",
    "Montage hulp tijdens verhuizen",
    "Opslag voor uw verhuizing",
  ]

  return (
    <>
      <SEO title={`Onze verhuisdienst ${frontmatter.title}`} />
      <SEO title={frontmatter.title} />
      <Hero
        usps={heroUsps}
        img={
          <Img
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={frontmatter.heroImage.childImageSharp.fluid}
          />
        }
      >
        <h1 className="font-weight-ligt">Onze</h1>
        <h1 className="font-weight-bold">verhuisdiensten</h1>
      </Hero>
      <section className="section--lg bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-7">
              <h5 className="font-weight-bold mb-1">
                Huis naar huis verhuizing
              </h5>
              <p className="mb-6">
                Dankzij 15 jaar ervaring in verhuizen zijn wij voor u de juiste
                partner voor het verhuizen van uw huis tegenover een gunstige
                tarief. Met professtionaliteit en zorg voor uw inboedel wordt
                elke verhuizing tot een goed einde gebracht. Van studio tot
                appartement of een vrijstaande woning.
              </p>
              <h5 className="font-weight-bold mb-1">Zakelijk verhuizen</h5>
              <p className="mb-6">
                Bij Verhuisdeal kunt u ook terecht voor uw zakelijke verhuizing.
                Wij verzorgen de verhuizing van uw bedrijf naar elk gewenste
                locatie, zowel binnen Nederland als in het buitenland. Of het nu
                om de verhuizing van uw inboedel gaat, of om uw machines of
                kantoorgoederen, Verhuisdeal brengt de goederen op de
                afgesproken tijd op de afgesproken locatie voor een gunstige
                prijs!
              </p>
              <h5 className="font-weight-bold mb-1">
                Internationaal verhuizen
              </h5>
              <p className="mb-6">
                Geen verhuis klus is te groot voor Verhuisdeal. Gaat u of bent u
                van plan om naar het buitenland te verhuizen? Wacht niet langer,
                want wij bij Verhuisdeal hebben ruim ervaring in internationale
                verhuizingen. Wij denken graag met u mee over uw verhuizing en
                bieden een passend en kwalitatieve oplossing tegen een zeer
                scherpe verhuis tarief. Inmiddels hebben wij al wat verhuis
                kilometers gemaakt naar o.a. Spanje, Italië, Zweden en Albanië.
              </p>
              <h5 className="font-weight-bold mb-1">Spoed verhuizing</h5>
              <p className="mb-6">
                Verhuisdeal is door de jaren heen gegroeid. De opgedane verhuis
                ervaringen heeft ons geleerd ook flexibel te zijn bij spoed
                verhuizingen. Met trots vertellen wij u ook dat wij verhuis
                oplossingen op maat bieden voor spoed verhuizingen. We kunnen u
                binnen 24 uur helpen aan een spoed verhuizer en/of verhuislift.
                Om zo een verhuizing snel te kunnen faciliteren adviseren wij u
                om eerst telefonisch <a href="tel:0203542540">(020-3542540)</a>{" "}
                contact met ons op te nemen, alvorens een verhuis offerte op te
                vragen. Zo kunnen wij u sneller begeleiden en opschalen waar
                nodig om uw spoed verhuizing snel en kwalitatief af te ronden.
              </p>
              <h5 className="font-weight-bold mb-1">Verhuislift huren</h5>
              <p className="mb-6">
                Bij verhuisdeal beschikken wij altijd over het juiste verhuis
                materiaal. Daarom kunt u bij ons ook terecht voor het huren van
                een verhuislift, waarmee verhuizen van boven verdiepingen in een
                rap tempo gaat. Vraag daarom altijd bij ons naar de
                beschikbaarheid van een verhuislift. Dat kunt u doen via{" "}
                <a href="https://wa.me/31622441648?text=">WhatsApp</a> of mail
                naar{" "}
                <a href="mailto:verhuislift.huren@verhuisdeal.nl">
                  verhuislift.huren@verhuisdeal.nl
                </a>
              </p>
              <h5 className="font-weight-bold mb-1">Woningontruiming</h5>
              <p className="mb-6">
                Woningontruiming komt nooit gelegen uit, het is een stressvolle
                moment met veel verschillende emoties. Bij Verhuisdeal begrijpen
                wij de minder prettige omstandigheden. Daarom proberen wij als
                betrouwbare ontruiming partner, tijdens een faillissement,
                huurachterstand of een overlijding u te ontstressen. Onze
                flexibilteit zorgt ervoor dat wij snel en adequaat kunnen
                reageren op dergelijke gevallen. Heeft u een woningontruiming
                met spoed? Dan adviseren wij u om ons telefonisch{" "}
                <a href="tel:0203542540">(020-3542540)</a> te contact, alvorens
                een verhuis offerte op te vragen.
              </p>
              <h5 className="font-weight-bold mb-1">
                Montage hulp tijdens verhuizen
              </h5>
              <p className="mb-6">
                Wij nemen al het verhuis werk uit uw handen! Als u een kast of
                bed heeft die ge(de)monteerd dient te worden door onze
                verhuizers voor een verhuizing, dan kunt u gebruik maken van de
                gratis montage service van Verhuisdeal. Wij demonteren uw te
                verhuizen goederen in uw huidige woning en monteren deze
                goederen weer in uw nieuwe woning zodat u gelijk klaar bent om
                in te trekken na de verhuizing.
              </p>
              <h5 className="font-weight-bold mb-1">
                Opslag voor uw verhuizing
              </h5>
              <p className="mb-6">
                Niet iedereen is een schrijnwerker, en een beschadigd meubel
                herstellen kost al snel bakken vol geld. Daarom beschermt
                Verhuisdeal uw inboedel optimaal tijdens de verhuizing en/of
                inboedel opslag. Kunt u nog niet naar uw nieuwe huis verhuizen?
                Of is de tijdelijke woning iets te klein voor uw inboedel? Dan
                kunt u gebruik maken van onze Opslag service. Wanneer u tussen 2
                adressen zit kunnen wij tijdelijk uw inboedel opslaan. U kunt
                dit aangeven in de 'opmerkingen' tijdens het aanvragen van een
                verhuis offerte. Wilt u alleen gebruik maken van onze Opslag
                service? Mail naar{" "}
                <a href="mailto:opslag.service@verhuisdeal24.nl">
                  opslag.service@verhuisdeal24.nl
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white d-md-none">
        <div className="row no-gutters">
          <div className="col-12">
            <Pricing></Pricing>
          </div>
        </div>
      </section>
      <Testimonials testimonials={testimonials} />
      <Banner />
      <StickyCTA></StickyCTA>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        testimonials {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`
